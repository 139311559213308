import gsap from "gsap";
let mm = gsap.matchMedia();
mm.add("(min-width: 480px)", () => {
   const btns = document.querySelectorAll(".btn__magnet-area");
   //const cursorBtnHover = document.querySelector(".cursor-btn-hover");

   const cursorBox = document.querySelector(".cursor-box");
   const cursor = document.querySelector(".cursor-def");

   btns.forEach((btn) => {
      // 1. Set the function and variables
      function parallaxItBtn(e, target, movement = 0.7) {
         var boundingRect = btn.getBoundingClientRect();
         var relX = e.pageX - boundingRect.left;
         var relY = e.pageY - boundingRect.top;
         var scrollTop = window.pageYOffset || document.documentElement.scrollTop;

         gsap.to(target, {
            x: (relX - boundingRect.width / 2) * movement,
            y: (relY - boundingRect.height / 2 - scrollTop) * movement,
            ease: "power4",
            duration: 0.6
         });
      }

      // 2. Call the function
      function callParallaxBtn(e) {
         parallaxItBtn(e, btn);
      }
      btn.addEventListener('mousemove', function (e) {
         callParallaxBtn(e);
      });


      var btnHover = btn.querySelector(".btn__magnet-content");
      //var darkBg = document.querySelectorAll('.dark-bg');


      btnHover.onmouseenter = function () {
         cursor.style.transform = "scale(1)";
         cursorBox.style.color = "#fff";
         cursorBox.style.mixBlendMode = "exclusion";
      };
      btnHover.onmouseleave = function () {
         cursor.style.transform = "scale(0.25)";
         cursorBox.style.color = "var(--color-1)";
         cursorBox.style.mixBlendMode = "";
      };




      btn.addEventListener('mouseleave', function (e) {
         gsap.to(btn, {
            scale: 1,
            x: 0,
            y: 0,
            ease: "power1",
            duration: 1.4
         });
      });


      ///
      //btn.addEventListener("mousemove", function (e) {
      //   const position = btn.getBoundingClientRect();
      //   const x = e.pageX - position.left - position.width / 2;
      //   const y = e.pageY - position.top - position.height / 2;

      //   btn.children[0].style.transform = "translate(" + x * 0.33 + "px, " + y * 0.7 + "px)";

      //   //btnHovBg.style.transform = "scale(1) translate(" + x * 0.33 + "px, " + y * 0.7 + "px)";



      //});
      ///
   });

   //btns.forEach((btn) => {
   //   //const btnHovBg = btn.querySelector(".btn__hov-bg");

   //   btn.addEventListener("mouseout", function (e) {
   //      btn.children[0].style.transform = "translate(0px, 0px)";

   //      //btnHovBg.style.transform = "scale(0) translate(0px, 0px)";
   //   });
   //});

});